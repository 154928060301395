import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_URL } from "../../API";
import axios from "axios";
import React from "react";
import moment from "moment";


const PrivateRoutes = () => {
	const userDetails = useSelector((state) => state.auth.userDetails);
	const [baselineStatus, setBaselineStatus] = React.useState(false);
	const [endlineStatus, setEndlineStatus] = React.useState(false);
	const [createdDate, setCreatedDate] = React.useState("");
	const [loginDate, setLoginDate] = React.useState("");
	const [role, setRole] = React.useState("");
	const [isLoading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const getBaselineStatus = async () => {
			try {
				const response = await axios.get(`${API_URL}/users/${userDetails.email}`);
				setBaselineStatus(response.data.isBaselineComplete);
				setEndlineStatus(response.data.isEndlineComplete);
				setCreatedDate(response.data.created_at);
				setLoginDate(response.data.loggedin_at);
				setRole(response.data.role);
				setLoading(false);
			} catch (err) {
				setLoading(false);
				console.log(err);
			}
		};
		getBaselineStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isLoading) {
		if (role === "admin" || role === "counsellor") {
			return <Outlet />;
		} else if (!baselineStatus) {
			return <Navigate to="/baseline" />;
		} else if (!endlineStatus) {
			return <Navigate to="/endline" />;
		} else { 
			return <Outlet />;
		} 
	}

	// Optionally, you can return a loading indicator or null here
	return null;
};

export default PrivateRoutes;
