/* eslint-disable react/prop-types */
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Moment from "react-moment";
import moment from "moment";
import ParticipantDialog from "../ParticipantDialog";
import SelectFilter from "../ui/SelectFilter";
import { FaDownload } from "react-icons/fa6";
import Papa from "papaparse";
import { Stack } from "@mui/material";
import dummydata from "../../utils/dummydata";

const headCells = [
	{
		id: "name",
		disablePadding: true,
		label: "Name",
	},
	{
		id: "university",
		numeric: true,
		disablePadding: false,
		label: "University",
	},
	{
		id: "created",
		numeric: true,
		disablePadding: false,
		label: "Date Of Participation",
	},
	{
		id: "loggedin",
		numeric: true,
		disablePadding: false,
		label: "Last Logged in",
	},
	{
		id: "details",
		numeric: true,
		disablePadding: false,
		label: "",
	},
];

function EnhancedTableHead() {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell key={headCell.id} align="left" padding={headCell.disablePadding ? "none" : "normal"}>
						<span className="font-websa-bold text-base">{headCell.label}</span>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function TableToolbar({ isBaselineComplete,
isEndlineComplete, noData, yesData, setBaseline, setEndline, setArm, downloadFn, refinedata, setUniversity }) {
	const options = [
		{ value: "Completed Baseline", bool: "true" },
		{ value: "Hasn't done Baseline", bool: "false" },
	];
	const endlineOptions = [
		{ value: "Completed Endline", bool: "true" },
		{ value: "Hasn't done Endline", bool: "false" },
	];
	const armOptions = [
		{ value: "Intervention arm", bool: "intervention" },
		{ value: "Control Arm", bool: "control" },
	];
	const universities = [
		{ value: "Makerere Univeristy", bool: "muk" },
		{ value: "MUBS", bool: "mubs" },
		{ value: "Kyambogo University", bool: "kyu" },
		{ value: "Uganda Christian University", bool: "ucu" },
		{ value: "Uganda Martyrs University Nkozi", bool: "umun" },
		{ value: "Ndejje University", bool: "nu" },
		{ value: "Kampala Internatonal University", bool: "kiu" },
	];

	const processDatasetToCSV = (dataset) => {
		return Papa.unparse(dataset, {
			escapeFormulae: true,
			header: true,
			skipEmptyLines: false,
			columns: null, // or specify column names if needed
		});
	};

	const combineCSVStrings = (csvString1, csvString2) => {
		// Split the CSV strings into rows
		const rows1 = csvString1.split("\n");
		const rows2 = csvString2.split("\n");

		// Combine the rows
		const combinedRows = rows1.concat(rows2);

		// Join the combined rows back into a single CSV string
		const combinedCSVString = combinedRows.join("\n");

		return combinedCSVString;
	};

	const exportCombinedCSV = (combinedCSVString) => {
		// Create a Blob containing the combined CSV data
		const csvBlob = new Blob([combinedCSVString], { type: "text/csv" });

		// Create a URL for the Blob
		const csvUrl = URL.createObjectURL(csvBlob);

		// Create an invisible link element to trigger the download
		const link = document.createElement("a");
		link.href = csvUrl;
		link.download = "Baseline Data.csv";

		link.click();

		// Clean up by revoking the URL to release resources
		URL.revokeObjectURL(csvUrl);
	};

	const csvString1 = processDatasetToCSV(noData);
	const csvString2 = processDatasetToCSV(yesData);

	const combinedCSVString = combineCSVStrings(csvString1, csvString2);

	const handleCSV = ()=>{
		if(isBaselineComplete || isEndlineComplete) {
			exportCombinedCSV(combinedCSVString);
		} else {
			downloadFn(refinedata)
		}
	}


	return (
		<Box className="pt-6 pb-4 flex justify-between items-end">
			<Stack direction="row" spacing={3} className="flex items-center w-[80%]">
				<div>
					<span className="text-sm">Sort by Arm:</span>
					<SelectFilter setMethod={setArm} options={armOptions} />
				</div>
				<div>
					<span className="text-sm">Sort by Baseline:</span>
					<SelectFilter setMethod={setBaseline} options={options} />
				</div>
				<div>
					<span className="text-sm">Sort by Endline:</span>
					<SelectFilter setMethod={setEndline} options={endlineOptions} />
				</div>
				<div>
					<span className="text-sm">Sort by Univeristy:</span>
					<SelectFilter setMethod={setUniversity} options={universities} />
				</div>
			</Stack>
			<div>
				<button onClick={handleCSV} className="button-small-green flex items-space space-y-2">
					<FaDownload className="mr-2" />
					Download Data
				</button>
			</div>
		</Box>
	);
}

export default function InterventionTable({ userData }) {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [isBaselineComplete, setBaseline] = React.useState(false);
	const [isEndlineComplete, setEndline] = React.useState(false);
	const [arm, setArm] = React.useState("");
	const [university, setUniversity] = React.useState("");

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function sortReferrals(a, b) {
		if (a.created_at > b.created_at) {
			return -1;
		} else {
			return 1;
		}
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

	const refineddata = userData
		.filter((value) => {
			if (arm === "intervention") {
				return value.selection === "intervention";
			} else if (arm === "control") {
				return value.selection === "control";
			} else {
				return value;
			}
		})
		.filter((value) => {
			if (isEndlineComplete === "false") {
				return value.isEndlineComplete === false;
			} else if (isEndlineComplete === "true") {
				return value.isEndlineComplete === true;
			} else {
				return value;
			}
		})
		.filter((value) => {
			if (isBaselineComplete === "false") {
				return value.isBaselineComplete === false;
			} else if (isBaselineComplete === "true") {
				return value.isBaselineComplete === true;
			} else {
				return value;
			}
		})
		.filter((value) => {
			if (university === "muk") return value.university === "Makerere University";
			if (university === "mubs") return value.university === "Makerere University Business School";
			if (university === "ucu") return value.university === "Uganda Christian University";
			if (university === "kiu") return value.university === "Kampala International University";
			if (university === "kyu") return value.university === "Kyambogo University";
			if (university === "umun") return value.university === "Uganda Martyrs University Nkozi";
			if (university === "nu") return value.university === "Ndejje University";
			return value;
		})
		.map((value, index) => {
			const refinedBaseline = value.baselineData.filter((elem) => elem !== null);
			const newdata = {
				Name: value.name,
				Email: value.email,
				Phone: value.phone,
				"Selection arm": value.selection,
				University: value.university,
				"Has done Baseline": value.isBaselineComplete === true ? "Yes" : "No",
				"Has done Endline": value.isEndlineComplete === true ? "Yes" : "No",
				"Last logged at": moment(value.loggedin_at).format("D-MMMM-YYYY"),
			};
			refinedBaseline?.forEach((item, index) => {
				let question;
				let answer;

				// Special handling for indices 11 to 14
				if (index >= 11 && index <= 14) {
					if (Array.isArray(item)) {
						question = item[0]?.question 
						answer = item.map((obj) => obj.answer).join(", ")
					} else {
						// If item is not an array, directly access the question and answer properties
						question = item?.question 
						answer = item?.answer
					}
				} else {
					// Handling for other indices
					if (Array.isArray(item)) {
						// If item is an array, access the first element's question and answer properties
						question = item[0]?.question 
						answer = item.map((obj) => obj.answer).join(", ")
					} else {
						// If item is not an array, directly access the question and answer properties
						question = item?.question 
						answer = item?.answer
					}
				}

				// Constructing the key for newdata
				let key = `Question ${index + 1}: `;
				if (index >= 11 && index <= 14) {
					// Special formatting for indices 11 to 14
					key += `${question},`;
				} else {
					key += `${question}`;
				}

				// Adding the entry to newdata
				newdata[key] = `Answer: ${answer}`;
			});

			return newdata;
		});

	const endlinedata = userData
		.filter((value) => {
			if (arm === "intervention") {
				return value.selection === "intervention";
			} else if (arm === "control") {
				return value.selection === "control";
			} else {
				return value;
			}
		})
		.filter((value) => {
			if (isEndlineComplete === "false") {
				return value.isEndlineComplete === false;
			} else if (isEndlineComplete === "true") {
				return value.isEndlineComplete === true;
			} else {
				return value;
			}
		})
		.filter((value) => {
			if (university === "muk") return value.university === "Makerere University";
			if (university === "mubs") return value.university === "Makerere University Business School";
			if (university === "ucu") return value.university === "Uganda Christian University";
			if (university === "kiu") return value.university === "Kampala International University";
			if (university === "kyu") return value.university === "Kyambogo University";
			if (university === "umun") return value.university === "Uganda Martyrs University Nkozi";
			if (university === "nu") return value.university === "Ndejje University";
			return value;
		})
		.map((value, index) => {
			const refinedEndline = value.endlineData.filter((elem) => elem !== null);
			const newdata = {
				Name: value.name,
				Email: value.email,
				Phone: value.phone,
				"Selection arm": value.selection,
				University: value.university,
				"Has done Baseline": value.isBaselineComplete === true ? "Yes" : "No",
				"Has done Endline": value.isEndlineComplete === true ? "Yes" : "No",
				"Last logged at": moment(value.loggedin_at).format("D-MMMM-YYYY"),
			};
			refinedEndline?.forEach((item, index) => {
				let question;
				let answer;

				// Special handling for indices 11 to 14
				if (index >= 19 && index <= 22) {
					if (Array.isArray(item)) {
						question = item[0]?.question;
						answer = item.map((obj) => obj.answer).join(", ");
					} else {
						// If item is not an array, directly access the question and answer properties
						question = item?.question;
						answer = item?.answer;
					}
				} else {
					// Handling for other indices
					if (Array.isArray(item)) {
						// If item is an array, access the first element's question and answer properties
						question = item[0]?.question;
						answer = item.map((obj) => obj.answer).join(", ");
					} else {
						// If item is not an array, directly access the question and answer properties
						question = item?.question;
						answer = item?.answer;
					}
				}

				// Constructing the key for newdata
				let key = `Question ${index + 1}: `;
				if (index >= 11 && index <= 14) {
					// Special formatting for indices 11 to 14
					key += `${question},`;
				} else {
					key += `${question}`;
				}

				// Adding the entry to newdata
				newdata[key] = `Answer: ${answer}`;
			});

			return newdata;
		});

	// Function to export data to CSV and trigger download
	const exportDataToCSV = (data) => {
		// Convert the data to CSV format using PapaParse
		const csv = Papa.unparse(data, {
			escapeFormulae: true,
			header: true,
			skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
			columns: null, //or array of strings
		});

		// Create a Blob containing the CSV data
		const csvBlob = new Blob([csv], { type: "text/csv" });

		// Create a URL for the Blob
		const csvUrl = URL.createObjectURL(csvBlob);

		// Create an invisible link element to trigger the download
		const link = document.createElement("a");
		link.href = csvUrl;
		link.download = "Intervention Participants.csv";

		link.click();

		// Clean up by revoking the URL to release resources
		URL.revokeObjectURL(csvUrl);
	};

	const yesData = refineddata.filter((value, index) => {
		if (value["Question 19: In the last 30 days, did you take 5 or more drinks at one time?"] === "Answer: Yes") {
			return value;
		}
		return undefined;
	});

	const noData = refineddata.filter((value, index) => {
		if (value["Question 19: In the last 30 days, did you take 5 or more drinks at one time?"] === "Answer: No") {
			return value;
		}
		return undefined;
	});

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }} className="px-5">
				<TableToolbar
					setBaseline={setBaseline}
					setEndline={setEndline}
					setArm={setArm}
					downloadFn={exportDataToCSV}
					refinedata={refineddata}
					setUniversity={setUniversity}
					noData={noData}
					yesData={yesData}
					isBaselineComplete={isBaselineComplete}
					isEndlineComplete={isEndlineComplete}
				/>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
						<EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={userData.length} />
						<TableBody>
							{userData
								.filter((value) => {
									if (arm === "intervention") {
										return value.selection === "intervention";
									} else if (arm === "control") {
										return value.selection === "control";
									} else {
										return value;
									}
								})
								.filter((value) => {
									if (isBaselineComplete === "false") {
										return value.isBaselineComplete === false;
									} else if (isBaselineComplete === "true") {
										return value.isBaselineComplete === true;
									} else {
										return value;
									}
								})
								.filter((value) => {
									if (isEndlineComplete === "false") {
										return value.isEndlineComplete === false;
									} else if (isEndlineComplete === "true") {
										return value.isEndlineComplete === true;
									} else {
										return value;
									}
								})
								.filter((value) => {
									if (university === "muk") return value.university === "Makerere University";
									if (university === "mubs") return value.university === "Makerere University Business School";
									if (university === "ucu") return value.university === "Uganda Christian University";
									if (university === "kiu") return value.university === "Kampala International University";
									if (university === "kyu") return value.university === "Kyambogo University";
									if (university === "umun") return value.university === "Uganda Martyrs University Nkozi";
									if (university === "nu") return value.university === "Ndejje University";
									return value;
								})
								.sort(sortReferrals)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
											<TableCell component="th" id={labelId} scope="row" padding="none">
												{row.name}
											</TableCell>
											<TableCell align="left">{row.university}</TableCell>
											<TableCell align="left">
												{" "}
												<Moment fromNow>{row.created_at}</Moment>
											</TableCell>
											<TableCell align="left">
												{" "}
												<Moment fromNow>{row.loggedin_at}</Moment>
											</TableCell>
											<TableCell align="left">
												<ParticipantDialog userEmail={row.email} />
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 100]}
					component="div"
					count={userData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
