import Container from "@mui/material/Container";
import "animate.css";
import RadioOptions from "../utils/RadioOptions";
import "animate.css";
import TextField from "../utils/TextField";
import React from "react";

const details = {
	question: "Did you experience any challenges using the APP?",
	options: [
		{ name: "Yes", value: "Yes" },
		{ name: "No", value: "No" },
	],
	direction: "row",
};

const details2 = {
	question: "If yes, briefly mention the top two challenges",
};

const Page76 = () => {
	const [conditionals, setConditional] = React.useState("");

	console.log(conditionals);
	return (
		<Container
			fixed
			sx={{
				paddingTop: "10%",
			}}
		>
			{conditionals === "Yes" ? (
				<>
					<h1 className="heading1">{details2.question}</h1>
					<TextField details={details2} />
				</>
			) : (
				<>
					<h1 className="heading1">{details.question}</h1>
					<RadioOptions details={details} grid={12} setConditional={setConditional} />
				</>
			)}
		</Container>
	);
};

export default Page76;
