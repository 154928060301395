import Container from "@mui/material/Container";
import "animate.css";
import RadioOptions from "../utils/RadioOptions";
import "animate.css";

const details = {
	question: "It was easy to navigate through the WEBSA APP",
	options: [
		{ name: "1", value: 1 },
		{ name: 2, value: 2 },
		{ name: 3, value: 3 },
		{ name: 4, value: 4 },
		{ name: 5, value: 5 },
		{ name: 6, value: 6 },
		{ name: 7, value: 7 },
		{ name: 8, value: 8 },
		{ name: 9, value: 9 },
		{ name: 10, value: 10 },
	],
	direction: "row",
};

const Page72 = () => {
	return (
		<Container
			fixed
			sx={{
				paddingTop: "10%",
			}}
		>
			<h1 className="heading1">{details.question}</h1>
			<RadioOptions details={details} />
		</Container>
	);
};

export default Page72;
