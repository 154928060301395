const dummydata = [
	{
		Name: "######################################",
		Email: "######################################",
		Phone: "######################################",
		"Selection arm": "######################################",
		University: "######################################",
		"Has done Baseline": "######################################",
		"Has done Endline": "######################################",
		"Last logged at": "######################################",
		"Question 1: Your academic program year of study?": "######################################",
		"Question 2: Accommodation during University semester time? ": "######################################",
		"Question 3: Who pays your fees (tuition, accommodation, functional and others)?": "######################################",
		"Question 4: Do you own a smart phone (and you are not using somebody else’s?": "######################################",
		"Question 5: What is your Religion? ": "###################################### Catholic",
		"Question 6: District of current residence? Country if not Ugandan": "######################################",
		"Question 7: District of previous residence if applicable? Country if not Ugandan": "######################################",
		"Question 8: Type of residence?": "###################################### Urban",
		"Question 9: How often do you use internet(say for news, source of reading materials, social media or more)":
			"######################################",
		"Question 10: Academic progression on the degree program": "######################################",
		"Question 11: What is your current CGPA": "######################################",
		"Question 12: Which of the listed have you ever taken in your life?,": "######################################",
		"Question 13: Which of the listed have you used in last 12 months?,": "######################################",
		"Question 14: Which of the listed have you used in last 30 days?,": "######################################",
		"Question 15: Of these substances  you used in last 30 days, which one do you take as primary?,": "######################################",
		"Question 16: How often have you used the primary drug in last week?": "######################################",
		"Question 17: How about in the last 30 days ": "######################################",
		"Question 18: What's your Gender?": "######################################",
		"Question 19: In the last 30 days, did you take 5 or more drinks at one time?": "yes",
		"Question 20: In the last 30 days, what is the number of standard drinks that was taken in last drinking session?":
			"######################################",
		"Question 21: In the last 30 days, what is the number of times you went home drunk?": "###################################### 0",
		"Question 22: Do you have a parent/ guardian who has a history of problematic alcohol or drug use?": "###################################### No",
		"Question 23: Do you have a brother or sister who has a history of problematic alcohol or drug use?": "###################################### Yes",
		"Question 24: Do you have a friend who engages in risky alcohol or other drug use?": "###################################### Yes",
		"Question 25: Have you failed to sit for an exam or continuous assessment or hand in a course work in the last 30 days  due to alcohol/drug use?":
			"###################################### No",
		"Question 26: Has your academic performance been affected by alcohol/drug use? Compare last 30 days and previous month":
			"###################################### No",
		"Question 27: Did you have a disciplinary issue related to alcohol/drug use with a lecturer or the institution in the last 30 days?":
			"###################################### No",
		"Question 28: Do you think there are adequate efforts by management in  your university to control access and use of alcohol and other drugs by students?":
			"###################################### Yes",
		"Question 29: Have you ever participated in a forum on risky alcohol and drug use awareness and prevention program in your institution?":
			"###################################### No",
		"Question 30: Are you an active member of any sport or club?": "###################################### No",
		"Question 31: In the last 30 days, have you experienced Extreme changes in mood?": "###################################### Yes",
		"Question 32: In the last 30 days, have you experienced: Withdrawal from people?": "###################################### Yes",
		"Question 33: In the last 30 days, have you experienced: Dramatic changes in eating or sleeping habits,?":
			"###################################### Yes",
		"Question 34: In the last 30 days, have you experienced: Feeling sad": "###################################### Yes",
		"Question 35: In the last 30 days, have you experienced: Feeling hopeless about the present or future, ":
			"###################################### Yes",
		"Question 36: In the last 30 days, have you experienced: Lacking interest in activities/hobbies": "###################################### Yes",
		"Question 37: In the last 30 days, have you experienced: Thinking about taking your life, ": "###################################### No",
		"Question 38: In the last 30 days, have you experienced: Attempted to harm yourself or attempted  suicide":
			"###################################### No",
		"Question 39: In the last 30 days, have you experienced: Been involved in criminal violence (domestic, fights in bars or elsewhere)":
			"###################################### No",
		"Question 40: In the last 30 days, have you experienced: Been involved in an accident connected with alcohol/drugs":
			"###################################### No",
	},
];
	

export default dummydata;
